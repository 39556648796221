interface Props {
  finishingPlaces: Record<string, string>[];
}

export default function PlayerRecords({ finishingPlaces }: Props) {
  if (!finishingPlaces) {
    return;
  }
  const rows = finishingPlaces.map((user) => (
    <tr className="text-center" key={user.username}>
      <td>{user.username}</td>
      <td>{user["1"]}</td>
      <td>{user["2"]}</td>
      <td>{user["3"]}</td>
      <td>{user.totalGames}</td>
    </tr>
  ));

  return (
    <table className="table table-bordered">
      <thead>
        <tr className="text-center">
          <td>Player</td>
          <td>First Place Finishes</td>
          <td>Second Place Finishes</td>
          <td>Third Place Finishes</td>
          <td>Total Games</td>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
