import Scoreboard from "./Scoreboard.tsx";
import { TurnUtil } from "../PlayGamePage/TurnUtil.ts";
import { PlayerTurnDto } from "../../../dtos/GameDtos.ts";

interface Props {
  playerTurns: PlayerTurnDto[];
  pointsToWin: number;
}

export default function FinishedGameScoreboard({ playerTurns, pointsToWin }: Props) {
  playerTurns.sort((a, b) => {
    if (a.turns.length > b.turns.length) {
      return 1;
    } else if (a.turns.length < b.turns.length) {
      return -1;
    } else {
      if (a.turns.length == 0) {
        return -1;
      } else if (b.turns.length == 0) {
        return 1;
      } else if (TurnUtil.getNumThrows(a.turns[-1]) > TurnUtil.getNumThrows(b.turns[-1])) {
        return 1;
      } else if (TurnUtil.getNumThrows(a.turns[-1]) < TurnUtil.getNumThrows(b.turns[-1])) {
        return -1;
      }
    }
    return 0;
  });

  return <Scoreboard playerTurns={playerTurns} pointsToWin={pointsToWin} firstAtTop={true} />;
}
