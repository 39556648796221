import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useAuth } from "../../hooks/AuthProvider.tsx";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../api/AuthenticationApi.ts";
import { useState } from "react";
import { useAxios } from "../../hooks/useAxios.ts";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import { LoginResponseDto } from "../../dtos/AuthenticationDtos.ts";

interface ErrorResponseData {
  nonFieldErrors?: string[];
}

const FormSchema = z.object({
  username: z
    .string()
    .min(3, {
      message: "Username must be at least 3 characters.",
    })
    .max(40, {
      message: "Username must be 40 chars or less.",
    }),
  password: z.string().min(1, {
    message: "Password must not be blank.",
  }),
});

export default function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { login } = useAuth();
  const navigate = useNavigate();
  const axios = useAxios();
  const [loginError, setLoginError] = useState<string>();

  function getNonFieldErrors(error: AxiosError): string | undefined {
    const data = error.response?.data as ErrorResponseData;

    if (data?.nonFieldErrors && data.nonFieldErrors.length > 0) {
      return data.nonFieldErrors[0];
    }
    return undefined;
  }

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    signIn(axios, data.username, data.password)
      .then((loginDto: LoginResponseDto) => {
        login(loginDto.token, data.username);
        navigate("/");
      })
      .catch((error) => {
        const nonFieldError = getNonFieldErrors(error);
        setLoginError(nonFieldError || "Error logging in...");
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="mb-3" controlId="formUsername">
        <FormLabel>Username</FormLabel>
        <FormControl type="text" placeholder="Enter username" {...register("username")} isInvalid={!!errors.username} />
        {errors.username && <Form.Control.Feedback type="invalid">{errors.username.message}</Form.Control.Feedback>}
      </FormGroup>
      <FormGroup className="mb-3" controlId="formPassword">
        <FormLabel>Password</FormLabel>
        <FormControl type="password" placeholder="********" {...register("password")} isInvalid={!!errors.password} />
        {errors.password && <Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>}
      </FormGroup>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <Link to="/forgot-password" style={{ marginBottom: "10px" }}>
          Forgot Password?
        </Link>{" "}
        {loginError && <div style={{ color: "red", marginBottom: "10px" }}>{loginError}</div>}
        <Button
          type="submit"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
            minWidth: "120px",
            padding: "0.5rem 1rem",
          }}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
}
