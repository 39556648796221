// App.tsx
import * as Sentry from "@sentry/react";
import Navbar from "./components/Navbar.tsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home.tsx";
import { SWRConfig } from "swr";
import { AuthProvider } from "./hooks/AuthProvider.tsx";
import SiteWideStats from "./components/SiteWideStats.tsx";
import { useFetcher } from "./hooks/userFetcher.ts";
import SignOut from "./components/Authentication/SignOut.tsx";
import SignUp from "./components/Authentication/SignUp.tsx";
import GameListPage from "./components/GameListPage/GameListPage.tsx";
import CreateGamePage from "./components/CreateGamePage/CreateGamePage.tsx";
import Notifications from "./components/Notifcations.tsx";
import NotificationView from "./components/NotificationView/NotifcationView.tsx";
import UserSearch from "./components/UserSearch";
import UserProfile from "./components/UserProfile.tsx";
import SignIn from "./components/Authentication/SignIn.tsx";
import GameView from "./components/GameView/GameView.tsx";
import FriendsPage from "./components/FriendsPage.tsx";
import ForgotPasswordPage from "./components/Authentication/ForgotPasswordPage.tsx";
import PasswordResetRequestSentPage from "./components/Authentication/PasswordResetRequestSentPage.tsx";
import ResetPasswordPage from "./components/Authentication/ResetPasswordPage.tsx";
import Settings from "./components/Settings";
import EmailVerification from "./components/EmailVerification.tsx";
import GameParticipants from "./components/GameListPage/GameParticipants.tsx";
import EditGameParticipants from "./components/GameListPage/EditGameParticipants.tsx";
import PrivacyPolicy from "./components/Privacy.tsx";

if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
  Sentry.init({
    dsn: "https://e2c255e29f0b2bb8eaa096c68a344e78@o4507633010606080.ingest.de.sentry.io/4507633012441168",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <SWRConfig value={{ fetcher: useFetcher() }}>
          <div>
            <Navbar />
            <div className="container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/site-wide-stats" element={<SiteWideStats />} />

                <Route path="/games/create/" element={<CreateGamePage />} />
                <Route path="/games/:gameId" element={<GameView />} />
                <Route path="/games/:gameId/participants" element={<GameParticipants />} />
                <Route path="/games/:gameId/participants/edit" element={<EditGameParticipants />} />
                <Route path="/games" element={<GameListPage />} />

                <Route path="/notifications" element={<Notifications />} />
                <Route path="/notifications/:notificationId" element={<NotificationView />} />

                <Route path="/user_search" element={<UserSearch />} />
                <Route path="/user/:username" element={<UserProfile />} />

                <Route path="/friends/:username" element={<FriendsPage />} />

                <Route path="/settings/:username" element={<Settings />} />

                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/sign-out" element={<SignOut />} />

                <Route path="/verify-email/:token" element={<EmailVerification />} />

                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/password-reset-request-sent" element={<PasswordResetRequestSentPage />} />
                <Route path="/password-reset/:token" element={<ResetPasswordPage />} />

                <Route path="/privacy" element={<PrivacyPolicy />} />
              </Routes>
            </div>
          </div>
        </SWRConfig>
      </AuthProvider>
    </Router>
  );
}

export default App;
