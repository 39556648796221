import { AxiosInstance } from "axios";
import { LoginResponseDto } from "../dtos/AuthenticationDtos.ts";

const loginUrl = "/users/signin/";
const resetPasswordResetUrl = "/users/password-reset-request-logged-in/";
const emailVerificationUrl = "/email/verify";

export async function signIn(axios: AxiosInstance, username: string, password: string) {
  const res = await axios.post<LoginResponseDto>(loginUrl, { username: username, password: password });
  return res.data;
}

export function verifyEmailRequest(axios: AxiosInstance, token: string) {
  return axios.get(emailVerificationUrl + "?token=" + token);
}

export function requestPasswordReset(axios: AxiosInstance) {
  return axios.get(resetPasswordResetUrl);
}
