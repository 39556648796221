import { TurnUtil } from "../PlayGamePage/TurnUtil.ts";
import { Point } from "../PlayGamePage/PointUtil.ts";
import { PlayerTurnDto } from "../../../dtos/GameDtos.ts";

interface Props {
  playerTurns: PlayerTurnDto[];
  pointsToWin: number;
  firstAtTop: boolean;
}

export default function Scoreboard({ playerTurns, pointsToWin, firstAtTop }: Props) {
  const playerPointsRemaining: { [id: string]: number } = {};

  const getTurn = (playerTurn: PlayerTurnDto, turnIndex: number): Point[] | null => {
    if (playerTurn && playerTurn.turns.length > turnIndex) {
      return playerTurn.turns[turnIndex];
    }
    return null;
  };

  const getPointsRemainingAfter = (username: string, turn: Point[]): number => {
    let pointsRemaining = playerPointsRemaining[username] || pointsToWin;

    if (!TurnUtil.willBeBust(turn, pointsRemaining)) {
      pointsRemaining -= TurnUtil.getTotalPoints(turn);
    }

    playerPointsRemaining[username] = pointsRemaining;
    return pointsRemaining;
  };

  const getStyle = (lowestScore: number, highestScore: number, pointsRemainingAfter: number, numValidThrows: number) => {
    if (pointsRemainingAfter === 0) {
      return { backgroundColor: "rgba(0,255,247,0.47)" };
    } else if (pointsRemainingAfter === lowestScore && numValidThrows > 1) {
      return { backgroundColor: "rgba(0,255,0,0.47)" };
    } else if (pointsRemainingAfter === highestScore && numValidThrows > 2) {
      return { backgroundColor: "rgba(248,90,90,0.47)" };
    } else {
      return {};
    }
  };

  const getRow = (turnIndex: number) => {
    const cellStrings = [];

    let lowestScore = pointsToWin;
    let highestScore = 0;

    for (const playerTurn of playerTurns) {
      const turn = getTurn(playerTurn, turnIndex);
      if (turn) {
        const pointsRemainingAfter = getPointsRemainingAfter(playerTurn.player, turn);
        const shorthand = TurnUtil.getJoinedShorthand(turn);
        lowestScore = Math.min(lowestScore, pointsRemainingAfter);
        highestScore = Math.max(highestScore, pointsRemainingAfter);
        cellStrings.push(`${shorthand} -> ${pointsRemainingAfter}`);
      } else {
        cellStrings.push(null);
      }
    }

    const numNonNullCellStrings = cellStrings.filter((cellString) => cellString !== null).length;
    if (numNonNullCellStrings === 0) {
      return null;
    }

    return (
      <>
        <td>{turnIndex + 1}</td>
        {cellStrings.map((cellString) => {
          if (cellString) {
            const splitCellString = cellString.split("->");
            const pointsRemainingAfter = Number(splitCellString[splitCellString.length - 1]);
            return <td style={getStyle(lowestScore, highestScore, pointsRemainingAfter, numNonNullCellStrings)}>{cellString}</td>;
          } else {
            return <td></td>;
          }
        })}
      </>
    );
  };

  const getRows = () => {
    const rows = [];

    let i = 0;
    while (true) {
      const row = getRow(i);
      if (!row) {
        break;
      }
      if (firstAtTop) {
        rows.push(<tr key={i}>{row}</tr>);
      } else {
        rows.unshift(<tr key={i}>{row}</tr>);
      }
      i++;
    }
    return rows;
  };

  return (
    <>
      <h2>Scoreboard</h2>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr className="text-center">
              <th>Turn</th>
              {playerTurns.map(({ player }) => (
                <th key={player}> {player}</th>
              ))}
            </tr>
          </thead>
          <tbody>{getRows()}</tbody>
        </table>
      </div>
    </>
  );
}
