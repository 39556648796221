import CollapsibleGameList from "./CollapsibleGameList.tsx";
import { GameOpenness } from "../../dtos/GameDtos.ts";

interface Props {
  openness: GameOpenness;
}

export default function GameList({ openness }: Props) {
  return (
    <>
      <div className="tab-content" id="gameTabsContent">
        <br />
        <CollapsibleGameList openness={openness} gameStatus="unfinished" title="Unfinished Games" />
        <CollapsibleGameList openness={openness} gameStatus="not_started" title="Not Started Games" />
        <CollapsibleGameList openness={openness} gameStatus="finished" title="Finished Games" />
      </div>
    </>
  );
}
