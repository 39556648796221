import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios.ts";
import { z } from "zod";

const usernameRegEx = new RegExp("^[a-zA-Z0-9_]+$");

const signupSchema = z
  .object({
    email: z.string().email("Invalid email address"),
    username: z
      .string()
      .min(4, "Username must be at least 4 characters long.")
      .regex(usernameRegEx, "Username must only contain alphanumeric characters as well as periods, ampersands, underscores, dashes, and exclamation marks."),
    password1: z.string().min(8, "Password must be at least 8 characters long"),
    password2: z.string().min(8, "Password must be at least 8 characters long"),
  })
  .refine((data) => data.password1 === data.password2, {
    message: "Passwords do not match",
    path: ["password1", "password2"],
  });

type SignupFormState = z.infer<typeof signupSchema>;

export default function SignUp() {
  const navigate = useNavigate();
  const axios = useAxios();

  const [formState, setFormState] = useState<SignupFormState>({
    email: "",
    username: "",
    password1: "",
    password2: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    try {
      signupSchema.parse(formState);
      setErrors({});
      return true;
    } catch (e) {
      if (e instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        e.errors.forEach((error) => {
          newErrors[error.path[0]] = error.message;
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      const { email, username, password1 } = formState;
      const payload = {
        email,
        username,
        password: password1,
      };
      await axios
        .post("/users/signup/", payload)
        .then(() => navigate("/sign-in"))
        .catch((error) => {
          if (error.response.status === 400) {
            const errorData = error.response.data;

            if (errorData.password1) {
              setErrors((prevErrors) => ({ ...prevErrors, password1: errorData.password1[0] }));
            }
            if (errorData.username) {
              setErrors((prevErrors) => ({ ...prevErrors, username: errorData.username[0] }));
            }
            if (errorData.email) {
              setErrors((prevErrors) => ({ ...prevErrors, email: errorData.email[0] }));
            }
          }
        });
    }
  };

  return (
    <div className="container mt-5">
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <input type="email" className={`form-control ${errors.email ? "is-invalid" : ""}`} id="email" name="email" value={formState.email} onChange={handleChange} required />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input type="text" className={`form-control ${errors.username ? "is-invalid" : ""}`} id="username" name="username" value={formState.username} onChange={handleChange} required />
          {errors.username && <div className="invalid-feedback">{errors.username}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="password1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className={`form-control ${errors.password1 ? "is-invalid" : ""}`}
            id="password1"
            name="password1"
            value={formState.password1}
            onChange={handleChange}
            required
          />
          {errors.password1 && <div className="invalid-feedback">{errors.password1}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="password2" className="form-label">
            Confirm Password
          </label>
          <input
            type="password"
            className={`form-control ${errors.password2 ? "is-invalid" : ""}`}
            id="password2"
            name="password2"
            value={formState.password2}
            onChange={handleChange}
            required
          />
          {errors.password2 && <div className="invalid-feedback">{errors.password2}</div>}
        </div>
        <button type="submit" className="btn btn-primary">
          Sign Up
        </button>
      </form>
    </div>
  );
}
