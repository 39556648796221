import useSWR from "swr";
import { useFetcher } from "../hooks/userFetcher.ts";
import { AxiosInstance } from "axios";
import { NotificationBasicDto, NotificationDetailDto } from "../dtos/NotificationDtos.ts";

const notificationListUrl = `/notifications/`;
const notificationDetailUrl = (notificationId: string) => `/notifications/${notificationId}/`;
const notificationBulkDeleteUrl = "/notifications/bulk-delete/";

export function useNotificationDetail(notificationId?: string) {
  return useSWR<NotificationDetailDto>(notificationDetailUrl(notificationId!), useFetcher());
}

export function useNotificationList() {
  return useSWR<NotificationBasicDto[]>(notificationListUrl!, useFetcher());
}

export async function sendDeleteNotification(axios: AxiosInstance, notificationId: string) {
  await axios.delete(notificationDetailUrl(notificationId));
}

export async function sendBulkDeleteNotification(axios: AxiosInstance, notificationIds: number[]) {
  const data = {
    ids: notificationIds,
  };
  await axios.post(notificationBulkDeleteUrl, data);
}
