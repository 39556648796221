import { Link, useNavigate, useParams } from "react-router-dom";
import { useProfile } from "../api/ProfileApi.ts";
import { Button } from "react-bootstrap";
import { updateFriendStatusRequest } from "../api/FriendsApi.ts";
import { useAxios } from "../hooks/useAxios.ts";
import { UserProfileDto } from "../dtos/ProfileDtos.ts";

// TODO: Reuse statistic component from site wide stats
export default function UserProfile() {
  const { username } = useParams();
  const navigate = useNavigate();
  const axios = useAxios();

  const { profile, isLoading, isError, mutate } = useProfile(username!);
  if (isError) return <div>Unable to fetch profile.</div>;

  if (isLoading) return <div>Loading profile...</div>;

  const updateFriendStatus = async (newUIStatus: string, newRequestStatus: string) => {
    const prevStatus = profile!.friendshipStatus;
    mutate({ ...profile, friendshipStatus: newUIStatus } as UserProfileDto, false);
    try {
      await updateFriendStatusRequest(axios, username!, newRequestStatus);
    } catch (error) {
      mutate({ ...profile, friendshipStatus: prevStatus } as UserProfileDto, false);
    }
  };

  const handleAcceptFriendClick = async () => updateFriendStatus("friends", "accept");
  const handleDenyFriendClick = async () => updateFriendStatus("none", "deny");
  const handleCancelFriendClick = () => updateFriendStatus("none", "cancel");
  const handleUnfriendClick = () => updateFriendStatus("none", "unfriend");
  const handleSendFriendClick = () => updateFriendStatus("requested", "create");

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 right-align-button" style={{ justifyContent: "space-between", display: "flex", alignItems: "center", width: "100%" }}>
            <h2>{username}'s Profile & Personal Stats</h2>
            {profile!.friendshipStatus === "need_to_answer" && (
              <>
                <Button className="btn btn-primary" onClick={handleAcceptFriendClick} role="button">
                  Accept Friend Request
                </Button>
                <Button className="btn btn-primary" onClick={handleDenyFriendClick} role="button">
                  Deny Friend Request
                </Button>
              </>
            )}
            {profile!.friendshipStatus === "requested" && (
              <Button className="btn btn-primary" onClick={handleCancelFriendClick} role="button">
                Cancel Friend Request
              </Button>
            )}
            {profile!.friendshipStatus === "friends" && (
              <Button className="btn btn-primary" onClick={handleUnfriendClick} role="button">
                Unfriend
              </Button>
            )}
            {profile!.friendshipStatus === "none" && (
              <Button className="btn btn-primary" onClick={handleSendFriendClick} role="button">
                Send Friend Request
              </Button>
            )}
            {profile!.friendshipStatus === "self" && (
              <>
                <Button className="btn btn-primary" onClick={() => navigate(`/friends/${username}`)} role="button">
                  Friends
                </Button>
                <Button className="btn btn-primary" onClick={() => navigate(`/settings/${username}`)} role="button">
                  Settings
                </Button>
              </>
            )}
          </div>
        </div>

        <hr />

        <p>
          <b>Highest Checkout: </b> {profile!.highestCheckout}
          {profile!.highestCheckout && (
            <>
              {" "}
              in <Link to={`/games/${profile!.highestCheckoutInGameGameId}`}> Game {profile!.highestCheckoutInGameGameId} </Link>
            </>
          )}
        </p>

        <p>
          <b>Most Points In One Turn: </b> {profile!.maxPointsInTurn}
        </p>

        <p>
          <b>Times thrown {profile!.maxPointsInTurn}: </b> {profile!.totalMaxPointsInTurnThrown}
        </p>

        <p>
          <b>Most times thrown {profile!.maxPointsInTurn} in game: </b> {profile!.mostMaxPointsInTurnThrownInGame}
          {profile!.mostMaxPointsInTurnThrownInGame && (
            <>
              {" "}
              in <Link to={`/games/${profile!.mostMaxPointsInTurnThrownInGameGameId}`}> Game {profile!.mostMaxPointsInTurnThrownInGameGameId} </Link>
            </>
          )}
        </p>

        <p>
          <b>Total Games: </b> {profile!.totalGames}
        </p>

        <p>
          <b>Total Turns: </b> {profile!.totalTurns}
        </p>

        <p>
          <b>Total Points: </b> {profile!.totalPoints}
        </p>

        <p>
          <b>Average Points Per Turn: </b> {profile!.turnAverage}
        </p>

        <p>
          <b>Average Points Per Turn When Turn Starts Above 80: </b> {profile!.turnAverageAbove80}
        </p>

        <p>
          <b>Average Points Per Turn When Turn Starts Above 100: </b> {profile!.turnAverageAbove100}
        </p>

        <p>
          <b>Most Zero Point Turns In A Row: </b> {profile!.maxConsecutive0PointTurns}
          {profile!.maxConsecutive0PointTurns && (
            <>
              {" "}
              in <Link to={`/games/${profile!.maxConsecutive0PointTurnsInGameGameId}`}> Game {profile!.maxConsecutive0PointTurnsInGameGameId} </Link>{" "}
            </>
          )}
        </p>

        <p>
          <b>Total Triple 20s Thrown: </b> {profile!.totalTriple20s}
        </p>

        <p>
          <b>Total Double Bulls Thrown: </b> {profile!.totalDoubleBulls}
        </p>

        <p>
          <b>Total Misses: </b> {profile!.totalMisses}
        </p>

        <p>
          <b>Total Bounces: </b> {profile!.totalBounces}
        </p>

        <p>
          <b>Best Game: </b> {profile!.bestGame}
          {profile!.bestGame && (
            <>
              {" "}
              in <Link to={`/games/${profile!.bestGameId}`}> Game {profile!.bestGameId} </Link>{" "}
            </>
          )}
        </p>

        <p>
          <b>Worst Game: </b> {profile!.worstGame}
          {profile!.worstGame && (
            <>
              {" "}
              in <Link to={`/games/${profile!.worstGameId}`}> Game {profile!.worstGameId} </Link>{" "}
            </>
          )}
        </p>

        <hr />

        <h4>Head-To-Head Records</h4>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <td>Player</td>
                <td>Wins</td>
                <td>Losses</td>
                <td>Ties</td>
                <td>Total Games</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(profile!.headToHead).map((player) => {
                const record = profile!.headToHead[player];
                return (
                  <tr key={player} className="text-center">
                    <td>{player}</td>
                    <td>{record.wins}</td>
                    <td>{record.losses}</td>
                    <td>{record.ties}</td>
                    <td>{record.wins + record.losses + record.ties}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h4>Turn Averages</h4>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <td>Turn</td>
                <td>Average</td>
              </tr>
            </thead>
            <tbody>
              {(profile!.turnAverages ?? []).map((average, index) => {
                return (
                  <tr className="text-center">
                    <td>{index + 1}</td>
                    <td>{average}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
