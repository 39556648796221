// SignOut.tsx
import { useAuth } from "../../hooks/AuthProvider.tsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios.ts";

export default function SignOut() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const axios = useAxios();

  const handleSignOut = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    axios.post("/users/signout/");
    logout();
    navigate("/");
  };

  return (
    <>
      <h1>Sign Out</h1>
      <p>To sign out please click the button below.</p>
      <form className="login" onSubmit={handleSignOut}>
        <button className="primaryAction btn btn-danger" type="submit">
          Sign Out
        </button>
      </form>
    </>
  );
}
