import axios from "axios";
import { useAuth } from "./AuthProvider.tsx";

export const useAxios = () => {
  const { authToken, logout } = useAuth();

  const api = axios.create({
    baseURL: `${import.meta.env.VITE_BACKEND_URL}`,
    headers: authToken ? { Authorization: `Token ${authToken}` } : {},
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logout();
        window.location.href = "/sign-in";
      }
      return Promise.reject(error);
    },
  );

  return api;
};
