import React, { useEffect } from "react";
import "./FlashingText.css";

interface FlashingTextProps {
  text: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const FlashingText: React.FC<FlashingTextProps> = ({ text, visible, setVisible }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [visible, setVisible]);

  const handleClick = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="flashing-text-overlay" onClick={handleClick}>
      <div className="flashing-text">{text}</div>
    </div>
  );
};

export default FlashingText;
