import { Point, PointUtil } from "./PointUtil.ts";

export class TurnUtil {
  // These incoming points will be in long form since they are directly from the button
  public static setFirstEmptyThrow(turn: Point[], segment: Point, selectedBed: string): Point[] {
    const throwString = PointUtil.toShorthand(segment === "Miss" || segment === "Bounce" ? segment : `${selectedBed} ${segment}`);

    let filled = false;

    return turn.map((throwItem) => {
      if (!filled && !throwItem) {
        filled = true;
        return throwString;
      }
      return throwItem;
    });
  }

  public static getCountedPoints(turn: Point[], pointsRemainingBefore: number): number {
    if (this.willBeBust(turn, pointsRemainingBefore)) {
      return 0;
    } else {
      return TurnUtil.getTotalPoints(turn);
    }
  }

  public static getJoinedShorthand(turn: Point[]): string {
    let shorthand = "";
    if (turn[0]) {
      shorthand += turn[0];
    }
    if (turn[1]) {
      shorthand += ", " + turn[1];
    }
    if (turn[2]) {
      shorthand += ", " + turn[2];
    }

    return shorthand;
  }

  public static getTotalPoints(turn: Point[]): number {
    let points = 0;
    if (turn[0]) {
      points += PointUtil.numPoints(turn[0]);
    }
    if (turn[1]) {
      points += PointUtil.numPoints(turn[1]);
    }
    if (turn[2]) {
      points += PointUtil.numPoints(turn[2]);
    }

    return points;
  }

  public static isValidForSubmission(turn: Point[], pointsRemaining: number): boolean {
    if (!turn[0] && !turn[1] && !turn[2]) {
      return false;
    }
    if (turn[0] && turn[1] && turn[2] && pointsRemaining > 1) {
      return true;
    }

    return TurnUtil.isLeftJustified(turn) && pointsRemaining <= 1;
  }

  public static isLastThrowDouble(turn: Point[]): boolean {
    const lastThrow = this.getLastThrow(turn);
    if (!lastThrow) {
      return false; // If there is no last throw then how is it a bust?
    }

    return PointUtil.getBed(lastThrow).toLowerCase() !== "d";
  }

  public static isBust(turn: Point[], pointsRemaining: number): boolean {
    if (pointsRemaining > 1) {
      return false;
    }

    if (pointsRemaining < 0 || pointsRemaining === 1) {
      return true;
    }

    // At this point, pointsRemaining must = 0
    return this.isLastThrowDouble(turn);
  }

  public static willBeBust(turn: Point[], pointsRemainingBeforeTurnApplied: number): boolean {
    return TurnUtil.isBust(turn, pointsRemainingBeforeTurnApplied - this.getTotalPoints(turn));
  }

  public static getNumThrows(turn: Point[]): number {
    if (!turn) {
      return 0;
    }
    let numThrows = 0;
    turn[0] !== null ? (numThrows += 1) : numThrows;
    turn[1] !== null ? (numThrows += 1) : numThrows;
    turn[2] !== null ? (numThrows += 1) : numThrows;
    return numThrows;
  }

  private static getLastThrow(turn: Point[]): string | null {
    if (turn[2]) {
      return turn[2];
    } else if (turn[1]) {
      return turn[1];
    } else {
      return turn[0];
    }
  }

  private static isLeftJustified(turn: Point[]): boolean {
    let seenEmpty = false;

    for (const point of turn) {
      if (!point) {
        seenEmpty = true;
      } else if (seenEmpty) {
        return false;
      }
    }

    return true;
  }
}
