import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useAxios } from "../../hooks/useAxios.ts";
import { searchUsers } from "../../api/UserSearchApi.ts";
import { UserDto } from "../../dtos/UserDtos.ts";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";

export default function UserSearch() {
  const axios = useAxios();
  const navigate = useNavigate();
  const [isUserSearchLoading, setIsUserSearchLoading] = useState(false);

  const userSearchRequester = async (inputValue: string) => {
    setIsUserSearchLoading(true);
    try {
      const response = await searchUsers(axios, inputValue);
      return response.data.map((user: UserDto) => ({
        label: user.username,
        value: user,
      }));
    } finally {
      setIsUserSearchLoading(false);
    }
  };

  const handleChange = (option: SingleValue<{ label: string; value: UserDto }>) => {
    if (option) {
      navigate(`/user/${option.value.username}`);
    }
  };

  return (
    <div>
      <Form>
        <h3>User Search</h3>
        <Col className="d-flex flex-grow-1">
          <AsyncSelect cacheOptions loadOptions={userSearchRequester} onChange={handleChange} isLoading={isUserSearchLoading} className="flex-grow-1" />
        </Col>
      </Form>
    </div>
  );
}
