// AuthProvider.tsx
import React, { createContext, useContext, useState } from "react";

type AuthContextType = {
  authToken: string | null;
  username: string | null;
  login: (token: string, username: string) => void;
  logout: () => void;
};

// Create the context with a default value that matches the AuthContextType
export const AuthContext = createContext<AuthContextType>({
  authToken: null, // Provide a default value for authToken
  username: null,
  login: () => {}, // Provide a default no-op function for login
  logout: () => {}, // Provide a default no-op function for logout
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem("authToken"));
  const [username, setUsername] = useState<string | null>(localStorage.getItem("username"));

  const login = (token: string, username: string) => {
    localStorage.setItem("authToken", token);
    localStorage.setItem("username", username);
    setAuthToken(token);
    setUsername(username);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("username");
    setAuthToken(null);
    setUsername(null);
  };

  // Provide the state and the updaters to the context
  return <AuthContext.Provider value={{ authToken, username, login, logout }}>{children}</AuthContext.Provider>;
};

// Hook to use the auth context
export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};
