import { MouseEvent, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/AuthProvider.tsx";
import BedSelection from "../../BedSelection";
import SelectedThrowsForm from "../SelectedThrowsForm";
import SegmentSelection from "../../SegmentSelection";
import { TurnUtil } from "./TurnUtil.ts";
import AverageScore from "./AverageScore.tsx";
import CheckoutDisplay from "./CheckoutDisplay.tsx";
import PlayGameScoreboard from "../Scoreboard/PlayGameScoreboard.tsx";
import { useAxios } from "../../../hooks/useAxios.ts";
import { Point } from "./PointUtil.ts";
import { Stack } from "react-bootstrap";
import FlashingText from "./FlashingTextProps";
import useRequiredParams from "../../../hooks/useRequiredParams.ts";
import { GameViewDto, PlayerTurnDto } from "../../../dtos/GameDtos.ts";
import { deleteLastTurnRequest, postNewTurn } from "../../../api/PlayGameApi.ts";

interface PlayGamePageProps {
  gameData: GameViewDto;
  playerTurns: PlayerTurnDto[];
  mutateTurns: (playerTurns: PlayerTurnDto[], revalidate?: boolean) => void;
}

export default function PlayGamePage({ gameData, playerTurns, mutateTurns }: PlayGamePageProps) {
  const { gameId } = useRequiredParams();
  const { username } = useAuth();
  const axios = useAxios();

  const [currentTurn, setCurrentTurn] = useState<Point[]>([null, null, null]);
  const [selectedBed, setSelectedBed] = useState("Single");

  const [flashTextVisible, setFlashTextVisible] = useState(false); // State to manage the visibility of the flashing text
  const [flashText, setFlashText] = useState(""); // State to manage the visibility of the flashing text

  const [submitButtonHidden, setSubmitButtonHidden] = useState(true);

  const handlePointClick = (segment: string) => {
    setCurrentTurn(TurnUtil.setFirstEmptyThrow(currentTurn, segment, selectedBed));
    setSelectedBed("Single");
  };

  useEffect(() => {
    if (TurnUtil.isValidForSubmission(currentTurn, pointsRemaining)) {
      // The last turn needs to be manually submitted
      if (pointsRemaining === 0 && !TurnUtil.isLastThrowDouble(currentTurn)) {
        setSubmitButtonHidden(false);
        return;
      } else {
        submitTurn();
      }
    }
    if (!submitButtonHidden) setSubmitButtonHidden(true);
  }, [currentTurn]);

  const getCurrentPlayerTurns = () => {
    const currentPlayerTurns = playerTurns.find((turn) => turn.player === username);
    if (currentPlayerTurns) {
      return currentPlayerTurns;
    }
    return {
      id: "",
      player: username!,
      turns: [],
      game: gameId,
      isDone: false,
      finishingPlace: 9999,
    };
  };

  const sumOfPoints = getCurrentPlayerTurns()!.turns.reduce((acc, turn) => acc + TurnUtil.getCountedPoints(turn, gameData.pointsToWin - acc), 0);
  const pointsRemaining = gameData.pointsToWin - TurnUtil.getTotalPoints(currentTurn) - sumOfPoints;

  async function submitTurn(event?: MouseEvent<HTMLButtonElement>) {
    if (event) {
      event.preventDefault(); // If this is from a button click, do not redirect
    }

    if (TurnUtil.isBust(currentTurn, pointsRemaining)) {
      setFlashText(TurnUtil.getTotalPoints(currentTurn).toString() + " (Bust)");
    } else {
      setFlashText(TurnUtil.getTotalPoints(currentTurn).toString());
    }
    setFlashTextVisible(true);

    const beforeAddedTurn = playerTurns;
    const withAddedTurn = [
      ...playerTurns.filter((turn) => turn.player !== username),
      {
        ...getCurrentPlayerTurns(),
        turns: [...getCurrentPlayerTurns()!.turns, currentTurn],
      },
    ];
    mutateTurns(withAddedTurn, false);
    setCurrentTurn([null, null, null]);

    // TODO: Have current turn be re-populated if it all fails
    postNewTurn(axios, getCurrentPlayerTurns().id, currentTurn).catch(() => mutateTurns(beforeAddedTurn, false));
  }

  async function handleDeleteTurn() {
    if (getCurrentPlayerTurns().turns.length === 0) return;

    setFlashText("Turn Deleted");
    setFlashTextVisible(true);

    const beforeDeletedTurn = playerTurns;
    const afterDeletedTurn = [
      ...playerTurns.filter((turn) => turn.player !== username),
      {
        ...getCurrentPlayerTurns(),
        turns: getCurrentPlayerTurns().turns.slice(0, -1),
      },
    ];
    mutateTurns(afterDeletedTurn, false);

    // Forces a "reload", if turn is deleted point entry needs to be re-evaluated with new remaining points
    setCurrentTurn([...currentTurn]);

    deleteLastTurnRequest(axios, getCurrentPlayerTurns().id).catch(() => {
      mutateTurns(beforeDeletedTurn, false);
      setCurrentTurn([...currentTurn]);
    });
  }

  if (sumOfPoints === gameData.pointsToWin) {
    const setIsDoneTrue = [
      ...playerTurns.filter((turn) => turn.player !== username),
      {
        ...getCurrentPlayerTurns(),
        isDone: true,
      },
    ];
    mutateTurns(setIsDoneTrue, false);
  }

  return (
    <>
      <div className="container mt-5">
        <h1 className="mb-4">
          Playing Game {gameId} to {gameData.pointsToWin} points - Throwing Turn {getCurrentPlayerTurns()!.turns.length + 1}
        </h1>

        <div id="point_entry_related">
          <div className="mb-3">
            <AverageScore playerTurn={getCurrentPlayerTurns().turns} sumOfPoints={sumOfPoints} />
            <CheckoutDisplay pointsRemaining={pointsRemaining} numThrows={TurnUtil.getNumThrows(currentTurn)} />
          </div>

          <SelectedThrowsForm turn={currentTurn} updateTurn={setCurrentTurn} pointsRemaining={pointsRemaining} submitTurn={submitTurn} submitButtonHidden={submitButtonHidden} />

          <Stack direction="horizontal" style={{ marginTop: "8px" }}>
            <strong>Points Remaining:</strong>
            <div id="points-remaining" style={{ marginLeft: "5px" }}>
              {pointsRemaining}
            </div>
          </Stack>

          <h2 className="mb-3" style={{ marginTop: "8px" }}>
            Enter Points
          </h2>

          <BedSelection clickedBed={selectedBed} setClickedBed={setSelectedBed} />

          <SegmentSelection handlePointClick={handlePointClick} selectedBed={selectedBed} disabledAllButtons={!submitButtonHidden} />
        </div>

        <br />

        <PlayGameScoreboard playerTurns={playerTurns} pointsToWin={gameData.pointsToWin} />

        <button type="submit" className="btn btn-primary" style={{ marginTop: "10px" }} onClick={handleDeleteTurn}>
          Delete Last Turn
        </button>
      </div>

      <FlashingText text={flashText} visible={flashTextVisible} setVisible={setFlashTextVisible} />
    </>
  );
}
