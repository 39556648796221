import { Link } from "react-router-dom";

interface Props {
  playerName: string;
  gameId: number;
}

export default function SiteWideStatisticGameLink({ playerName, gameId }: Props) {
  return (
    <div style={{ display: "inline" }}>
      &nbsp;most recently thrown by {playerName} in <Link to={"/games/" + gameId}>Game {gameId}</Link>
    </div>
  );
}
