import { Loader } from "lucide-react";
import PlayGamePage from "./PlayGamePage";
import FinishedGameView from "./FinishedGameView";
import { createNewTurn, useGameDto, useTurnDtos } from "../../api/PlayGameApi.ts";
import { useAuth } from "../../hooks/AuthProvider.tsx";
import { useAxios } from "../../hooks/useAxios.ts";
import { useEffect, useState } from "react";
import useRequiredParams from "../../hooks/useRequiredParams.ts";

export default function GameView() {
  const { gameId } = useRequiredParams();
  const { username } = useAuth();
  const axios = useAxios();
  const { gameData } = useGameDto(gameId);
  const { turnData, mutateTurnData } = useTurnDtos(gameId);
  const [turnCreated, setTurnCreated] = useState(false);

  useEffect(() => {
    if (!turnData || !gameData || turnCreated) return;

    const currentPlayerTurns = turnData.find((turn) => turn.player === username);

    if (!currentPlayerTurns) {
      createNewTurn(axios, gameData.id).then((createdPlayerTurn) => {
        mutateTurnData([...turnData, createdPlayerTurn.data], false);
        setTurnCreated(true);
      });
    } else {
      setTurnCreated(true);
    }
  }, [turnData, gameData, username, turnCreated, axios, mutateTurnData]);

  if (!(gameData && turnData)) return <Loader />;

  const currentPlayerTurns = turnData?.find((turn) => turn.player === username);

  if (gameData && turnData) {
    return currentPlayerTurns?.isDone ? (
      <FinishedGameView gameData={gameData} turnData={turnData} />
    ) : (
      <PlayGamePage gameData={gameData} playerTurns={turnData} mutateTurns={mutateTurnData} />
    );
  }

  return <div>No game data available.</div>;
}
