import useSWR from "swr";
import SiteWideStatistic from "./SiteWideStatistic.tsx";
import PlayerRecords from "./PlayerRecords.tsx";
import { useFetcher } from "../hooks/userFetcher.ts";

export default function SiteWideStats() {
  const { data, isLoading } = useSWR("/stats/site-wide", useFetcher());

  if (isLoading) return <h1>Loading</h1>;

  return (
    <div className="container mt-5">
      <h2>Quick Game Stats</h2>
      <SiteWideStatistic name="Total Games Created" newStatistic={data.totalGamesCreated} />
      <SiteWideStatistic name="Total Games Played" newStatistic={data.totalPlayerTurns} />
      <hr />

      <h2>Quick Player Stats</h2>
      <SiteWideStatistic name="Total Players Registered" newStatistic={data.totalPlayersRegistered} />
      <hr />

      <h2>Site Wide Stats</h2>
      <hr />
      <SiteWideStatistic
        name="Highest Checkout"
        newStatistic={data.highestCheckout}
        playerName={data.highestCheckoutPlayerUsername}
        gameId={data.highestCheckoutInGameGameId}
      />
      <SiteWideStatistic name="Times 180 thrown" newStatistic={data.total180s} />
      <SiteWideStatistic
        name="Most 180s In A Game (501 only)"
        newStatistic={data.most180sInGame}
        playerName={data.most180sInGamePlayerUsername}
        gameId={data.most180sInGameGameId}
      />
      <SiteWideStatistic name="Total Turns" newStatistic={data.totalTurns} />
      <SiteWideStatistic name="Total Points" newStatistic={data.totalPoints} />
      <SiteWideStatistic name="Average Points Per Turn" newStatistic={data.turnAverage} />
      <SiteWideStatistic name="Average Points Per Turn When Turn Starts Above 80" newStatistic={data.turnAverageAbove80} />
      <SiteWideStatistic name="Average Points Per Turn When Turn Starts Above 100" newStatistic={data.turnAverageAbove100} />
      <SiteWideStatistic
        name="Most Zero Point Turns In A Row"
        newStatistic={data.maxConsecutive0PointTurns}
        playerName={data.maxConsecutive0PointTurnsPlayerUsername}
        gameId={data.maxConsecutive0PointTurnsInGameGameId}
      />

      <SiteWideStatistic name="Total Triple 20s Thrown" newStatistic={data.totalTriple20s} />
      <SiteWideStatistic name="Total Double Bulls Thrown" newStatistic={data.totalDoubleBulls} />
      <SiteWideStatistic name="Total Misses" newStatistic={data.totalMisses} />
      <SiteWideStatistic name="Total Bounces" newStatistic={data.totalBounces} />

      <SiteWideStatistic
        name="Best Game (501 only)"
        newStatistic={data.bestGame}
        playerName={data.bestGamePlayerUsername}
        gameId={data.bestGameId}
      />

      <SiteWideStatistic
        name="Worst Game (501 only)"
        newStatistic={data.worstGame}
        playerName={data.worstGamePlayerUsername}
        gameId={data.worstGameId}
      />

      <hr />

      <h4>Players and their finishing places</h4>
      <PlayerRecords finishingPlaces={data.finishingPlaces} />
    </div>
  );
}
