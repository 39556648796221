import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAxios } from "../../hooks/useAxios.ts";

const FormSchema = z.object({
  email: z.string().email({ message: "Invalid email address." }),
});

export default function ForgotPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
    },
  });
  const axios = useAxios();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // TODO: Move this where all the other API stuff is
  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    await axios
      .post("/users/password-reset-request/", data)
      .then(() => navigate("/password-reset-request-sent"))
      .catch((error) => {
        if (error.response && error.response.data.email) {
          setError(error.response.data.email[0]);
        } else {
          setError("Failed to send reset email. Please try again.");
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="reset-password-form">
      <FormGroup controlId="formBasicEmail" className="mb-3">
        <FormLabel>Email address</FormLabel>
        <FormControl type="email" placeholder="Enter email" isInvalid={!!errors.email} {...register("email")} />
        {errors.email && <FormControl.Feedback type="invalid">{errors.email.message}</FormControl.Feedback>}
      </FormGroup>
      {error && <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>}
      <Button type="submit" className="mt-3">
        Send Reset Link
      </Button>
    </Form>
  );
}
