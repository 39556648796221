import useSWR from "swr";
import { useFetcher } from "../hooks/userFetcher.ts";
import { UserProfileDto } from "../dtos/ProfileDtos.ts";

const profileUrl = (username: string) => `/users/profile/${username}/`;

export function useProfile(username: string) {
  const { data, error, mutate } = useSWR<UserProfileDto>(profileUrl(username), useFetcher());
  return {
    profile: data,
    isLoading: !data,
    isError: !!error,
    mutate,
  };
}
