export type Point = string | null;

export class PointUtil {
  public static bedToLonghand(shorthandBed: string) {
    switch (shorthandBed) {
      case "T":
        return "Triple"
      case "D":
        return "Double"
      default:
        return "Single"
    }
  }

  public static toLonghand(shortPoint: string | null): Point {
    if (shortPoint === null) {
      return null;
    }

    switch (shortPoint) {
      case ("MI"):
        return "Miss";
      case ("BO"):
        return "Bounce Off";
      case ("SB"):
        return "Single Bullseye";
      case ("DB"):
        return "Double Bullseye";
      default:
        return this.bedToLonghand(shortPoint[0]) + " " + shortPoint.slice(1);
    }
  }

  public static toShorthand(longPoint: string | null): Point {
    if (longPoint === null) {
      return null;
    }

    switch (longPoint) {
      case ("Miss"):
        return "MI";
      case ("Bounce"):
        return "BO";
      case ("Single Bull"):
        return "SB";
      case ("Double Bull"):
        return "DB";
      default:
        return longPoint.split(" ")[0][0] + longPoint.split(" ")[1];
    }
  }

  public static numPoints(point: Point): number {
    if (!point) {
      return 0;
    }

    const baseValue = parseInt(point.slice(1), 10);

    switch (point) {
      case "SB":
        return 25;
      case "DB":
        return 50;
      case "MI":
        return 0;
      case "BO":
        return 0;
      default:
        if (point.startsWith("S")) {
          return baseValue;
        } else if (point.startsWith("D")) {
          return 2 * baseValue;
        } else if (point.startsWith("T")) {
          return 3 * baseValue;
        } else {
          throw new Error("Invalid point value");
        }
    }
  }

  public static getBed(point: Point): string {
    if (!point) {
      throw new Error("Trying to get bed from a null point");
    }
    switch (point) {
      case "MI":
        return "S";
      case "BO":
        return "S";
      default:
        return point[0];
    }
  }
}
