import { useEffect, useState } from "react";
import useSWR from "swr";
import { useFetcher } from "../../hooks/userFetcher.ts";
import { GameDataDto, GameOpenness } from "../../dtos/GameDtos.ts";
import GameCard from "./GameCard.tsx";

interface Props {
  openness: GameOpenness;
  gameStatus: string;
  title: string;
}

export default function CollapsibleGameList({ openness, gameStatus, title }: Props) {
  const [isOpen, setOpen] = useState(gameStatus !== "finished");

  const [localData, setLocalData] = useState<GameDataDto[] | null | undefined>(null);

  const { data } = useSWR<GameDataDto[]>(isOpen ? "/games/list/" + openness + "/" + gameStatus : null, useFetcher());

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
  }, [data]);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <div className="tab-pane fade show active">
        <div className="d-flex justify-content-between align-items-center">
          <button className="btn btn-toggle-collapse w-100" onClick={handleClick}>
            <div className="d-flex justify-content-between align-items-center w-100">
              <h2 className="mb-0">
                {title} - {localData && localData.length}
              </h2>
            </div>
          </button>
        </div>

        <div className={"collapse " + (isOpen ? "show" : "")}>
          <div className="row row-cols-1 row-cols-md-3 g-4 mt-3">{localData && localData.map((game, index) => <GameCard key={index} gameData={game} />)}</div>
        </div>
        <hr className="my-4" />
      </div>
    </>
  );
}
