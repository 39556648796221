import { addParticipants, editOpenness, removeParticipants, useGameParticipantList, useValidNextGameOpenesses } from "../../api/GameListApi.ts";
import { Link, useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios.ts";
import { useState } from "react";
import { Button, Col, Form, FormGroup, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import useRequiredParams from "../../hooks/useRequiredParams.ts";
import { searchUsers } from "../../api/UserSearchApi.ts";
import Select, { MultiValue, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { GameOpenness } from "../../dtos/GameDtos.ts";
import { useGameDto } from "../../api/PlayGameApi.ts";
import { UserDto } from "../../dtos/UserDtos.ts";

export default function EditGameParticipants() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { gameId } = useRequiredParams();
  const { gameData, mutateGameData } = useGameDto(gameId);
  const { gameParticipants, mutateParticipants } = useGameParticipantList(gameId);
  const { validOpenesses } = useValidNextGameOpenesses(gameId);

  const [checkedPlayers, setCheckedPlayers] = useState<UserDto[]>([]);
  const [selectedPlayersForAddition, setSelectedPlayersForAddition] = useState<UserDto[]>([]);
  const [selectedNewOpenness, setSelectedNewOpenness] = useState<GameOpenness | null>(null);
  const [selectAllPlayersChecked, setSelectAllPlayersChecked] = useState<boolean>(false);
  const [isUserSearchLoading, setIsUserSearchLoading] = useState(false);

  const newOpennessOptions = validOpenesses
    ? validOpenesses!.validNewOpennesses.map((openness) => ({
        value: openness,
        label: openness,
      }))
    : [];

  const isUserChecked = (userToCheck: UserDto): boolean => {
    return checkedPlayers.some((user: UserDto) => user.username === userToCheck.username);
  };

  const handlePlayerCheckboxChange = (effectedUser: UserDto) => {
    // We are unchecking it in this situation
    if (isUserChecked(effectedUser)) {
      setSelectAllPlayersChecked(false);
      setCheckedPlayers((prev: UserDto[]) => prev.filter((user: UserDto) => user.username !== effectedUser.username));
    } else {
      const allParticipantsSelected = gameParticipants!.notStarted
        .filter((user: UserDto) => user.username !== effectedUser.username) // Filter out player that was just selected because we know they were selected
        .every((user: UserDto) => isUserChecked(user));
      setSelectAllPlayersChecked(allParticipantsSelected);
      setCheckedPlayers((prev: UserDto[]) => [...prev, effectedUser]);
    }
  };

  const handleSelectAll = () => {
    if (selectAllPlayersChecked) {
      setCheckedPlayers([]);
    } else {
      setCheckedPlayers(gameParticipants!.notStarted);
    }
    setSelectAllPlayersChecked((prev) => !prev);
  };

  const handleSubmittingNewOpenness = () => {
    if (!gameData || !selectedNewOpenness || gameData.openness === selectedNewOpenness) {
      return;
    }

    const beforeAdding = gameData.openness;

    mutateGameData(
      {
        ...gameData,
        openness: selectedNewOpenness,
      },
      false,
    );

    mutateParticipants({ ...gameParticipants! });
    editOpenness(axios, gameId, selectedNewOpenness).catch(() => {
      mutateGameData(
        {
          ...gameData,
          openness: beforeAdding,
        },
        false,
      );
      mutateParticipants({ ...gameParticipants! });
    });

    setSelectAllPlayersChecked(false);
    setCheckedPlayers([]);
    setSelectedPlayersForAddition([]);
  };

  const handleAddingSelected = () => {
    const beforeAdding = [...gameParticipants!.notStarted];
    const participantsAfterAdding = [...gameParticipants!.notStarted, ...selectedPlayersForAddition];

    mutateParticipants(
      {
        ...gameParticipants!,
        notStarted: participantsAfterAdding,
      },
      false,
    );

    addParticipants(axios, gameId, selectedPlayersForAddition).catch(() => {
      mutateParticipants(
        {
          ...gameParticipants!,
          notStarted: beforeAdding,
        },
        false,
      );
    });

    setSelectAllPlayersChecked(false);
    setCheckedPlayers([]);
    setSelectedPlayersForAddition([]);
  };

  const handleDeleteSelected = () => {
    const beforeDeletion = [...gameParticipants!.notStarted];
    const participantsAfterRemoval = gameParticipants!.notStarted!.filter((participant) => !isUserChecked(participant));

    mutateParticipants(
      {
        ...gameParticipants!,
        notStarted: participantsAfterRemoval,
      },
      false,
    );

    removeParticipants(axios, gameId, checkedPlayers).catch(() => {
      mutateParticipants(
        {
          ...gameParticipants!,
          notStarted: beforeDeletion,
        },
        false,
      );
    });

    setSelectAllPlayersChecked(false);
    setCheckedPlayers([]);
    setSelectedPlayersForAddition([]);
  };

  const isOpennessDisabled = (option: { label: GameOpenness; value: GameOpenness }) => {
    return option.value == gameData?.openness;
  };

  const isUserDisabled = (option: { label: string; value: UserDto }) => {
    const isInNotStarted = gameParticipants!.notStarted.some((participant: UserDto) => participant.username === option.value.username);
    const isInUnfinished = gameParticipants!.unfinished.some((participant: UserDto) => participant.username === option.value.username);
    const isInFinished = gameParticipants!.finished.some((participant: UserDto) => participant.username === option.value.username);

    return isInNotStarted || isInUnfinished || isInFinished;
  };

  const userSearchRequester = async (inputValue: string) => {
    setIsUserSearchLoading(true);
    try {
      const response = await searchUsers(axios, inputValue);
      return response.data.map((user: UserDto) => ({
        label: user.username,
        value: user,
      }));
    } finally {
      setIsUserSearchLoading(false);
    }
  };

  const handleChange = (options: MultiValue<{ label: string; value: UserDto }>) => {
    setSelectedPlayersForAddition(options.map((option: { label: string; value: UserDto }) => option.value));
  };

  const handleOpennessChange = (option: SingleValue<{ label: GameOpenness; value: GameOpenness }>) => {
    setSelectedNewOpenness(option?.value ?? null);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h1>
          Editing Game Participants: {gameId} - {gameData?.openness || "..."}
        </h1>
        <Button onClick={() => navigate(`/games/${gameId}/participants`)}>Back</Button>
      </div>
      {gameParticipants && gameData && gameData.openness === GameOpenness.INVITE && (
        <>
          <Form>
            {gameParticipants.notStarted.length > 0 && (
              <>
                <h3>Not Started - {gameParticipants.notStarted.length}</h3>
                {gameParticipants.notStarted.length > 0 && (
                  <FormGroup className="d-flex align-items-center m-0">
                    <Form.Check type="checkbox" id="select-all-checkbox" checked={selectAllPlayersChecked} label="Select All" onClick={handleSelectAll} />
                  </FormGroup>
                )}
                <ListGroup>
                  {gameParticipants?.notStarted.map((participant: UserDto) => (
                    <ListGroupItem key={participant.username} className="d-flex align-items-center justify-content-between">
                      <FormGroup className="d-flex align-items-center m-0">
                        <Form.Check
                          type="checkbox"
                          checked={isUserChecked(participant)}
                          id={participant.username}
                          label={participant.username}
                          onClick={() => handlePlayerCheckboxChange(participant)}
                        />
                      </FormGroup>
                      <Link to={`/user/${participant.username}`}>
                        <Button variant="primary" size="sm">
                          View Profile
                        </Button>
                      </Link>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </>
            )}
            {checkedPlayers.length > 0 && (
              <Button variant="danger" onClick={handleDeleteSelected} style={{ marginTop: "10px" }}>
                Remove Selected
              </Button>
            )}
            <h3>Add Players</h3>
            <Row style={{ marginTop: "15px" }}>
              <Col className="d-flex flex-grow-1">
                <AsyncSelect
                  isMulti
                  cacheOptions
                  loadOptions={userSearchRequester}
                  onChange={handleChange}
                  value={selectedPlayersForAddition.map((user: UserDto) => ({ label: user.username, value: user }))}
                  isLoading={isUserSearchLoading}
                  isOptionDisabled={isUserDisabled}
                  className="flex-grow-1"
                />
              </Col>
              <Col xs="auto">
                <Button variant="primary" onClick={handleAddingSelected}>
                  Add Selected Players
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <h2>Change Openness</h2>
      <Row style={{ marginTop: "15px" }}>
        <Col className="d-flex flex-grow-1">
          <Select options={newOpennessOptions} onChange={handleOpennessChange} className="flex-grow-1" isOptionDisabled={isOpennessDisabled} />
        </Col>
        <Col xs="auto">
          <Button variant="primary" onClick={handleSubmittingNewOpenness}>
            Change Openness
          </Button>
        </Col>
      </Row>
    </>
  );
}
