import styles from "./SegmentSelection.module.css";
import { Button } from "react-bootstrap";

interface SegmentSelectionProps {
  handlePointClick: (segmentToBeSet: string) => void;
  selectedBed: string;
  disabledAllButtons: boolean;
}

export default function SegmentSelection({ handlePointClick, selectedBed, disabledAllButtons: disableAllButtons }: SegmentSelectionProps) {
  const isDisabled = (segment: string) => {
    if (segment === "Miss" && selectedBed !== "Single") {
      return true;
    }

    if (segment === "Bull" && selectedBed === "Triple") {
      return true;
    }

    if (segment === "Bounce" && selectedBed !== "Single") {
      return true;
    }

    return false;
  };

  const getSegmentButton = (segment: string) => (
    <td key={segment} className={styles.textCenter}>
      <Button
        variant="outline"
        onClick={() => handlePointClick(segment)}
        className={segment === "Bounce" ? styles.bounceButton : styles.buttonSmall}
        disabled={isDisabled(segment) || disableAllButtons}
      >
        {segment}
      </Button>
    </td>
  );

  const getRow = (row: string[]) => <tr key={row[0] + row[1]}>{row.map((segment) => getSegmentButton(segment))}</tr>;

  const points_array = [
    ["1", "2", "3", "4", "5"],
    ["6", "7", "8", "9", "10"],
    ["11", "12", "13", "14", "15"],
    ["16", "17", "18", "19", "20"],
    ["Miss", "Bull", "Bounce"],
  ];

  return (
    <div className={styles.tableResponsive}>
      <table id="points-table" className={styles.tableFixed}>
        <tbody>{points_array.map((row) => getRow(row))}</tbody>
      </table>
    </div>
  );
}
