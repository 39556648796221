import Leaderboard from "../Leaderboard";
import FinishedGameScoreboard from "../Scoreboard/FinishedGameScoreboard.tsx";
import { GameViewDto, PlayerTurnDto } from "../../../dtos/GameDtos.ts";

interface FinishedGameViewProps {
  gameData: GameViewDto;
  turnData: PlayerTurnDto[];
}

export default function FinishedGameView({ gameData, turnData }: FinishedGameViewProps) {
  return (
    <>
      <h1>Game {gameData.id}</h1>
      <hr />
      <Leaderboard playerTurns={turnData} pointsToWin={gameData.pointsToWin} />
      <FinishedGameScoreboard playerTurns={turnData} pointsToWin={gameData.pointsToWin} />
    </>
  );
}
