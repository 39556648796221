import { useEffect, useState } from "react";

interface Props {
  pointsRemaining: number;
  numThrows: number;
}

interface Checkouts {
  [key: string]: string[];
}

export default function CheckoutDisplay({ pointsRemaining, numThrows }: Props) {
  const [checkoutMessage, setCheckoutMessage] = useState<string>("");

  const checkouts: Checkouts = {
    "170": ["T20, T20, DB"],
    167: ["T20, T19, DB"],
    164: ["T20, T18, DB", "T19, T19, DB"],
    161: ["T20, T17, DB"],
    160: ["T20, T20, D20"],
    158: ["T20, T20, D19"],
    157: ["T20, T19, D20"],
    156: ["T20, T20, D18"],
    155: ["T20, T19, D19"],
    154: ["T20, T18, D20"],
    153: ["T20, T19, D18"],
    152: ["T20, T20, D16"],
    151: ["T20, T17, D20", "T19, T18, D20"],
    150: ["T20, T18, D18", "T19, T19, D18"],
    149: ["T20, T19, D16"],
    148: ["T20, T20, D14", "T19, T17, D20"],
    147: ["T20, T17, D18", "T19, T18, D18"],
    146: ["T20, T18, D16", "T19, T19, D16"],
    145: ["T20, T19, D14"],
    144: ["T20, T20, D12"],
    143: ["T20, T17, D16", "T19, T18, D16"],
    142: ["T20, T14, D20", "T19, T19, D14"],
    141: ["T20, T19, D12"],
    140: ["T20, T20, D10"],
    139: ["T20, T13, D20", "T20, T19, D11"],
    138: ["T20, T18, D12", "T19, T19, D12"],
    137: ["T20, T19, D10"],
    136: ["T20, T20, D8"],
    135: ["T20, T17, D12", "SB, T20, DB"],
    134: ["T20, T16, D13"],
    133: ["T20, T19, D8"],
    132: ["T20, T16, D12", "SB, T19, DB"],
    131: ["T19, T14, D16", "T20, T13, D16"],
    130: ["T20, T20, D5"],
    129: ["T19, T16, D12", "T20, T19, D6"],
    128: ["T18, T14, D16", "T20, T18, D7"],
    127: ["T20, T17, D8"],
    126: ["T19, T19, D6"],
    125: ["T18, T19, D7", "T20, T15, D10"],
    124: ["T20, T14, D11"],
    123: ["T19, T16, D9"],
    122: ["T18, T18, D7"],
    121: ["T20, T11, D14", "T17, T20, D5"],
    120: ["T20, 20, D20"],
    119: ["T19, T12, D13"],
    118: ["T20, 18, D20"],
    117: ["T19, 20, D20", "T20, 17, D20"],
    116: ["T19, 19, D20", "T20, 16, D20"],
    115: ["T20, 15, D20", "T19, 18, D20"],
    114: ["T19, 17, D20", "T20, 14, D20"],
    113: ["T19, 16, D20"],
    112: ["T20, T12, D8"],
    111: ["T19, 14, D20", "T20, 11, D20"],
    110: ["T20, T10, D10", "T20, DB"],
    109: ["T20, 9, D20"],
    108: ["T20, 16, D16", "T20, 8, D20"],
    107: ["T19, T10, D10", "T19, DB"],
    106: ["T20, T10, D8"],
    105: ["T20, 13, D16"],
    104: ["T19, 15, D16", "T18, DB"],
    103: ["T19, 6, D20", "T19, 10, D18"],
    102: ["T20, 10, D16", "T20, 6, D18"],
    101: ["T20, 9, D16", "T17, DB"],
    100: ["T20, D20"],
    99: ["T19, 10, D16", "T19, 6, D18"],
    98: ["T20, D19"],
    97: ["T19, D20"],
    96: ["T20, D18"],
    95: ["T19, D19", "SB, T20, D5"],
    94: ["T18, D20", "SB, T19, D6"],
    93: ["T19, D18", "SB, T18, D7"],
    92: ["T20, D16", "SB, T17, D8"],
    91: ["T17, D20", "SB, T16, D9"],
    90: ["T20, D15", "T18, D18"],
    89: ["T19, D16"],
    88: ["T20, D14"],
    87: ["T17, D18"],
    86: ["T18, D16"],
    85: ["T15, D20", "T19, D14"],
    84: ["T20, D12"],
    83: ["T17, D16"],
    82: ["DB, D16", "T14, D20"],
    81: ["T19, D12", "T15, D18"],
    80: ["T20, D10", "D20, D20"],
    79: ["T19, D11", "T13, D20"],
    78: ["T18, D12"],
    77: ["T19, D10"],
    76: ["T20, D8", "T16, D14"],
    75: ["T17, D12"],
    74: ["T14, D16"],
    73: ["T19, D8"],
    72: ["T16, D12", "T20,D6"],
    71: ["T13, D16"],
    70: ["T18, D8", "T20, D5"],
    69: ["T19, D6"],
    68: ["T20, D4", "T16, D10", "T18, D7"],
    67: ["T9, D20", "T17, D8"],
    66: ["T10, D18", "T18, D6", "T16, D9"],
    65: ["T11, D16", "T19, D4", "T15, D10"],
    64: ["T16, D8", "T14, D11"],
    63: ["T13, D12", "T17, D6", "T13, D12"],
    62: ["T10, D16", "T12, D13"],
    61: ["T15, D8", "T7, D20", "T11, D14"],
    60: ["20, D20"],
    59: ["19, D20"],
    58: ["18, D20"],
    57: ["17, D20"],
    56: ["T16, D4"],
    55: ["15, D20"],
    54: ["14, D20"],
    53: ["13, D20"],
    52: ["12, D20", "20, D16"],
    51: ["11, D20", "19, D16"],
    50: ["10, D20", "18, D16", "DB"],
    49: ["9, D20", "17, D16"],
    48: ["8, D20", "16, D16"],
    47: ["7, D20", "15, D16"],
    46: ["10, D18", "6, D20"],
    45: ["13, D16", "19, D13"],
    44: ["12, D16", "4, D20"],
    43: ["3, D20", "11, D16"],
    42: ["10, D16", "6, D18"],
    41: ["9, D16"],
    40: ["D20"],
    39: ["S7, D16", "S19, D10"],
    38: ["D19"],
    37: ["S5, D16"],
    36: ["D18"],
    35: ["S3, D16"],
    34: ["D17"],
    33: ["S1, D16", "S17, D8"],
    32: ["D16"],
    31: ["S15, D8", "S19, D6"],
    30: ["D15"],
    29: ["S13 D8"],
    28: ["D14"],
    27: ["S11, D8", "S7, D10", "S19, D4"],
    26: ["D13"],
    25: ["S9, D8"],
    24: ["D12"],
    23: ["S7, D8"],
    22: ["D11"],
    21: ["S5, D8", "S13, D4"],
    20: ["D10"],
    19: ["S3, D8", "S11, D4"],
    18: ["D9"],
    17: ["S1, D8", "S9, D4"],
    16: ["D8"],
    15: ["S7, D4"],
    14: ["D7"],
    13: ["S5, D4"],
    12: ["D6"],
    11: ["S3, D4"],
    10: ["D5"],
    9: ["S1, D4"],
    8: ["D4"],
    7: ["S3, D2"],
    6: ["D3"],
    5: ["S1, D2"],
    4: ["D2"],
    3: ["S1, D1"],
    2: ["D1"],
  };

  useEffect(() => {
    getCheckouts();
  }, [pointsRemaining, numThrows]);

  const getCheckouts = () => {
    const throwsRemaining = 3 - numThrows;
    if (throwsRemaining === 0) {
      setCheckoutMessage("No Possible Checkouts");
      return;
    }

    const allPossibleCheckouts = checkouts[pointsRemaining] || [];
    const displayedCheckouts: string[] = [];
    for (const possibleCheckout of allPossibleCheckouts) {
      if (possibleCheckout.split(", ").length <= throwsRemaining) {
        displayedCheckouts.push(possibleCheckout);
      }
    }

    if (displayedCheckouts.length === 0) {
      setCheckoutMessage("No Possible Checkouts");
    } else if (displayedCheckouts.length === 1) {
      setCheckoutMessage("Possible Checkout: " + displayedCheckouts[0]);
    } else {
      setCheckoutMessage("Possible Checkouts: " + displayedCheckouts.join(" or "));
    }
  };

  return <div>{checkoutMessage}</div>;
}
