import { Link } from "react-router-dom";
import { MouseEvent, useState } from "react";
import GameList from "./GameList.tsx";
import { GameOpenness } from "../../dtos/GameDtos.ts";

export default function GameListPage() {
  const [displayedTab, setDisplayedTab] = useState(GameOpenness.OPEN);

  const handleTabClick = (event: MouseEvent<HTMLElement>) => {
    setDisplayedTab(event.currentTarget.id as GameOpenness);
  };

  return (
    <>
      <h1>Game Page</h1>
      <Link to="/games/create" className="btn btn-primary">
        Create New Game
      </Link>
      <p /> {/* TODO: Do something else for spacing*/}
      <ul className="nav nav-tabs" id="gameTabs" role="tablist">
        <li className="nav-item">
          <button className={"nav-link " + (displayedTab === GameOpenness.OPEN ? "active" : "")} id={GameOpenness.OPEN} onClick={handleTabClick}>
            Open
          </button>
        </li>
        <li className="nav-item">
          <button className={"nav-link " + (displayedTab === GameOpenness.FRIENDS ? "active" : "")} id={GameOpenness.FRIENDS} onClick={handleTabClick}>
            Friends
          </button>
        </li>
        <li className="nav-item">
          <button className={"nav-link " + (displayedTab === GameOpenness.INVITE ? "active" : "")} id={GameOpenness.INVITE} onClick={handleTabClick}>
            Invite
          </button>
        </li>
      </ul>
      <GameList openness={displayedTab} />
    </>
  );
}
