import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { useFriendsList } from "../api/FriendsApi.ts";
import { Loader } from "lucide-react";

export default function FriendsPage() {
  const navigate = useNavigate();

  const { friendList, isLoading } = useFriendsList();

  const handleUserClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
    navigate(`/user/${(event.target as HTMLElement).innerHTML}`);

  if (isLoading) return <Loader />;

  return (
    <>
      <h1>Friends</h1>
      <br />
      {friendList!.friends.length > 0 && <h2>Current Friends</h2>}
      <ListGroup variant="flush">
        {friendList!.friends.map((friend) => (
          <ListGroup.Item key={friend} onClick={handleUserClick}>
            {friend}
          </ListGroup.Item>
        ))}
      </ListGroup>

      {friendList!.pendingFriendRequests.length > 0 && <h2>Pending Friends</h2>}
      <ListGroup variant="flush">
        {friendList!.pendingFriendRequests.map((friend) => (
          <ListGroup.Item key={friend} onClick={handleUserClick}>
            {friend}
          </ListGroup.Item>
        ))}
      </ListGroup>

      {friendList!.waitingForResponseFriendRequests.length > 0 && <h2>Waiting for Responses</h2>}
      <ListGroup variant="flush">
        {friendList!.waitingForResponseFriendRequests.map((friend) => (
          <ListGroup.Item key={friend} onClick={handleUserClick}>
            {friend}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}
