import { Point } from "./PointUtil.ts";

interface Props {
  playerTurn: Point[][];
  sumOfPoints: number;
}

export default function AverageScore({ playerTurn, sumOfPoints }: Props) {
  const averagePoints = playerTurn && playerTurn.length !== 0 ? sumOfPoints / playerTurn.length : 0;

  return <div>Average Points Per Turn: {+averagePoints.toFixed(2)}</div>;
}
