export default function Home() {
  return (
    <>
      <h1>Home</h1>

      <p>
        This is a site to play darts asynchronously. All games are double out, maybe in the future I'll set it up so you can play with
        different rules but for now it's all double out. You can choose how many points to set your games to, but most stats are only kept
        for games played to 501.
      </p>

      <p>
        You can create global games, games between friends, or very specific invite only games. If you want to create a game just for
        yourself to play and log your points you can create an invite only game and invite nobody. If you want to create a practice game
        that is also an option, but that means no stats will be kept for that game so don't be mad if you throw a 180 and it doesn't add it
        to your personal stats.
      </p>

      <p>
        This is an honor system website, so just don't cheat. There's no point. This website is for playing your friends so there's no real
        reason to cheat. If you want to play super official games where web cams have to be involved so you know other people aren't
        cheating there are *plenty* of other websites out there.
      </p>

      <p>
        In terms of distance please play by the official distance rules (or as close is possible). You can find the official setup
        measurments{" "}
        <a href="https://www.good-darts.com/adviser/dart-distance-and-height.html" target="_blank">
          here
        </a>
        .
      </p>

      <p>
        This started out as just a small project to be able to play darts asynchronously with the boys, and then became a bit of a fun
        project with me using it to learn different frameworks and languages. If you have any feature requests email me at{" "}
        <a href="mailto:async.darts@gmail.com">async.darts@gmail.com</a>. Also, same goes for bugs and stuff like that.
      </p>
    </>
  );
}
