import styles from "./SelectedThrowsForm.module.css";
import { Point, PointUtil } from "../PlayGamePage/PointUtil.ts";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

interface SelectedThrowsFormProps {
  turn: Point[];
  updateTurn: (value: ((prevState: Point[]) => Point[]) | Point[]) => void;
  pointsRemaining: number;
  submitTurn: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  submitButtonHidden: boolean;
}

export default function SelectedThrowsForm({ turn, updateTurn, submitTurn, submitButtonHidden }: SelectedThrowsFormProps) {
  const xButton = "\u2715";

  function deleteThrow(throwNumber: number) {
    const updatedTurn = [...turn];
    updatedTurn[throwNumber - 1] = null;
    updateTurn(updatedTurn);
  }

  const getThrowEntrySpot = (throwNumber: number) => (
    <div className={styles.inputGroup}>
      <label>Throw {throwNumber}:</label>
      <input type="text" className="form-control" id="throwOneInput" name="throwOne" value={PointUtil.toLonghand(turn[throwNumber - 1]) || ""} disabled />
      <button type="button" onClick={() => deleteThrow(throwNumber)} className={styles.clearButton}>
        {xButton}
      </button>
    </div>
  );

  return (
    <Form method="post">
      <Row>
        <Col>{getThrowEntrySpot(1)}</Col>
        <Col>{getThrowEntrySpot(2)}</Col>
        <Col>{getThrowEntrySpot(3)}</Col>
      </Row>
      <button id="save-turn-button" type="submit" className="btn btn-primary" onClick={submitTurn} hidden={submitButtonHidden}>
        Save Turn
      </button>
    </Form>
  );
}
