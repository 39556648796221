import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios.ts";
import { createNewGame } from "../../api/PlayGameApi.ts";
import UserSearchSelector from "../UserSearchSelector";
import React, { useState } from "react";
import { z } from "zod";
import { CreateGameDto, GameOpenness } from "../../dtos/GameDtos.ts";
import { UserDto } from "../../dtos/UserDtos.ts";

export interface CreateGameResponseDto {
  id: number;
  points_to_win: number;
  owner: string;
  openness: string;
  is_practice: boolean;
  invited_players: string[];
}

const CreateGameSchema = z.object({
  points_to_win: z.number().int().positive().min(2, "Points to win must be at least 2"),
  openness: z.nativeEnum(GameOpenness),
  is_practice: z.boolean(),
  invited_players: z.array(z.string()).optional(),
});

export default function CreateGamePage() {
  const navigate = useNavigate();
  const axios = useAxios();

  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [pointsToWin, setPointsToWin] = useState("501");
  const [openness, setOpenness] = useState("open");
  const [isPractice, setIsPractice] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const createGameRequest = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default form submission behavior

    const gameData: CreateGameDto = {
      points_to_win: parseInt(pointsToWin, 10), // Convert string to integer
      openness,
      is_practice: isPractice,
    };

    if (openness === "invite") {
      gameData.invited_players = selectedUsers.map((user) => user.username);
    }

    try {
      CreateGameSchema.parse(gameData); // Validate using zod schema
      createNewGame(axios, gameData).then((response) => navigate("/games/" + response.data.id));
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrorMessage(error.errors.map((err) => err.message).join(", "));
      }
    }
  };

  return (
    <>
      <div className="container mt-5">
        <h2>Create a New Game</h2>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <div className="form-group">
          <label htmlFor="gamePoints">Game Points To Be Played To:</label>
          <select className="form-control" id="gamePoints" name="gamePoints" value={pointsToWin} onChange={(e) => setPointsToWin(e.target.value)} required>
            <option value="301">301</option>
            <option value="501">501</option>
            <option value="701">701</option>
            <option value="901">901</option>
            <option value="1001">1001</option>
            <option value="10001">10001</option>
          </select>

          <div className="form-check mt-3">
            <input type="checkbox" className="form-check-input" id="is_practice" name="is_practice" checked={isPractice} onChange={(e) => setIsPractice(e.target.checked)} />
            <label className="form-check-label" htmlFor="is_practice">
              Is Practice Game
            </label>
          </div>

          <label htmlFor="openness" className="mt-3">
            Openness:
          </label>
          <select className="form-control" id="openness" name="openness" value={openness} onChange={(e) => setOpenness(e.target.value)} required>
            <option value={GameOpenness.OPEN}>Open</option>
            <option value={GameOpenness.FRIENDS}>Friends Only</option>
            <option value={GameOpenness.INVITE}>Invite Players</option>
          </select>
        </div>

        {openness === GameOpenness.INVITE && <UserSearchSelector selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />}

        <button type="submit" className="btn btn-primary mt-3" onClick={createGameRequest}>
          Create Game
        </button>
      </div>
    </>
  );
}
