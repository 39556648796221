import { AxiosInstance } from "axios";
import useSWR from "swr";
import { useFetcher } from "../hooks/userFetcher.ts";
import { Point } from "../components/GameView/PlayGamePage/PointUtil.ts";
import { CreateGameResponseDto } from "../components/CreateGamePage/CreateGamePage.tsx";
import { CreateGameDto, GameViewDto, PlayerTurnDto } from "../dtos/GameDtos.ts";

const gamePlayUrl = (gameId: string) => `/games/${gameId}/`;
const turnsByGameUrl = (gameId: string) => `/turns/game/${gameId}/`;
const turnsThrowUrl = (turnId: string) => `/turns/${turnId}/throws/`;

export function useGameDto(gameId: string | undefined) {
  const { data, error, mutate } = useSWR<GameViewDto>(gamePlayUrl(gameId!), useFetcher());
  return {
    gameData: data,
    isError: !!error,
    mutateGameData: mutate,
  };
}

export function useTurnDtos(gameId: string | undefined) {
  const { data, error, mutate } = useSWR<PlayerTurnDto[]>(turnsByGameUrl(gameId!), useFetcher());
  return {
    turnData: data,
    isError: !!error,
    mutateTurnData: mutate,
  };
}

export async function createNewTurn(axios: AxiosInstance, gameId: string) {
  return await axios.post<PlayerTurnDto>(turnsByGameUrl(gameId));
}

export async function postNewTurn(axios: AxiosInstance, turnId: string, currentTurn: Point[]) {
  await axios.post(turnsThrowUrl(turnId), currentTurn);
}

export async function deleteLastTurnRequest(axios: AxiosInstance, turnId: string) {
  await axios.delete(turnsThrowUrl(turnId!));
}

export async function createNewGame(axios: AxiosInstance, gameData: CreateGameDto) {
  return await axios.post<CreateGameResponseDto>("/games/", gameData);
}
