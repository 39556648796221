import SiteWideStatisticGameLink from "./SiteWideStatisticGameLink.tsx";

interface Props {
  name: string;
  newStatistic: string;
  playerName?: string;
  gameId?: number;
}

export default function SiteWideStatistic({ name, newStatistic, playerName, gameId }: Props) {
  return (
    <p>
      <b>{name}: </b> {newStatistic}
      {playerName && gameId && <SiteWideStatisticGameLink playerName={playerName} gameId={gameId} />}
    </p>
  );
}
