import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function PasswordResetRequestSentPage() {
  const navigate = useNavigate();

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6} className="text-center">
          <h2>Password Reset Email Sent</h2>
          <p>
            An email has been sent to your email address with instructions on how to reset your password. Please check your inbox and
            follow the instructions to reset your password.
          </p>
          <p>
            If you don't receive the email within a few minutes, check your spam folder or
            <Link to="/forgot-password"> try again</Link>.
          </p>
          <Button
            variant="primary"
            onClick={() => {
              navigate("/sign-in"); // Navigate to sign in page
            }}
          >
            Return to Sign In
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
