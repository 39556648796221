import styles from "./BedSelection.module.css";

interface Props {
  clickedBed?: string;
  setClickedBed: (value: ((prevState: string) => string) | string) => void;
}

// TODO: Refactor
export default function BedSelection({ clickedBed, setClickedBed }: Props) {
  const getButton = (buttonName: string) => (
    <button
      className={`btn btn-outline-secondary btn-sm ${styles.button} ${clickedBed === buttonName && "active"}`}
      style={{ flex: 1 }}
      onClick={() => setClickedBed(buttonName)}
    >
      {buttonName}
    </button>
  );

  const singleButton = getButton("Single");
  const doubleButton = getButton("Double");
  const tripleButton = getButton("Triple");

  return (
    <div className={`mb-4 ${styles.container}`} id="bed-selection">
      {singleButton}
      {doubleButton}
      {tripleButton}
    </div>
  );
}
