import { requestPasswordReset } from "../../api/AuthenticationApi.ts";
import { useAxios } from "../../hooks/useAxios.ts";
import { Button } from "react-bootstrap";

export default function Settings() {
  const axios = useAxios();

  function showPopup(message: string) {
    const popup = document.createElement("div");
    popup.textContent = message;
    popup.style.position = "fixed";
    popup.style.top = "50%";
    popup.style.left = "50%";
    popup.style.transform = "translate(-50%, -50%)";
    popup.style.padding = "20px";
    popup.style.backgroundColor = "#fff";
    popup.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.1)";
    document.body.appendChild(popup);

    // Remove popup after 3 seconds
    setTimeout(() => {
      document.body.removeChild(popup);
    }, 3000);
  }

  function requestPasswordResetEmail() {
    requestPasswordReset(axios)
      .then(() => showPopup("Password reset e-mail has been sent."))
      .catch(() => showPopup("Failed to send the password reset e-mail."));
  }

  return (
    <div>
      <h1>Settings</h1>
      <Button className="btn btn-primary" onClick={requestPasswordResetEmail} role="button">
        Request email to reset password
      </Button>
    </div>
  );
}
