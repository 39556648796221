import { useState, useEffect } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { useAxios } from "../../hooks/useAxios.ts";
import { searchUsers } from "../../api/UserSearchApi.ts";
import { useAuth } from "../../hooks/AuthProvider.tsx";
import { UserDto } from "../../dtos/UserDtos.ts";

interface UserSearchSelectorProps {
  selectedUsers: UserDto[];
  setSelectedUsers: (value: ((prevState: UserDto[]) => UserDto[]) | UserDto[]) => void;
}

export default function UserSearchSelector({ selectedUsers, setSelectedUsers }: UserSearchSelectorProps) {
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState<UserDto[]>([]);
  const [potentialUsers, setPotentialUsers] = useState<UserDto[]>([]);
  const { username } = useAuth();

  const axios = useAxios();

  useEffect(() => {
    if (query.length > 3) {
      searchUsers(axios, query).then((response) => setUsers(response.data));
    } else {
      setUsers([]);
    }
  }, [query]);

  useEffect(() => {
    setPotentialUsers(users.filter((user: UserDto) => !selectedUsers.includes(user) && user.username !== username));
  }, [users, selectedUsers]);

  const handleSelectUser = (user: UserDto) => {
    if (!selectedUsers.find((selectedUser) => selectedUser.username === user.username)) {
      setSelectedUsers((prevUsers) => [...prevUsers, user]);
    }
  };

  const handleRemoveUser = (username: string) => {
    setSelectedUsers((prevUsers) => prevUsers.filter((user) => user.username !== username));
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>Search Users</Form.Label>
          <Form.Control type="text" placeholder="Enter username" value={query} onChange={(e) => setQuery(e.target.value)} />
        </Form.Group>
      </Form>
      {potentialUsers.length == 0 && (
        <ListGroup>
          <ListGroup.Item key="no-users">{query.length > 3 ? "No users found." : "Please type at least four characters to search."}</ListGroup.Item>
        </ListGroup>
      )}
      {potentialUsers.length > 0 && (
        <ListGroup>
          {potentialUsers.map((user) => (
            <ListGroup.Item key={user.username}>
              {user.username}
              <Button variant="primary" size="sm" onClick={() => handleSelectUser(user)} style={{ float: "right" }}>
                Select
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      {selectedUsers.length > 0 && (
        <div>
          <h4>Selected Users</h4>
          <ListGroup>
            {selectedUsers.map((user) => (
              <ListGroup.Item key={user.username}>
                {user.username}
                <Button variant="danger" size="sm" onClick={() => handleRemoveUser(user.username)} style={{ float: "right" }}>
                  Remove
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
    </div>
  );
}
