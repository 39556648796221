import Scoreboard from "./Scoreboard.tsx";
import { useAuth } from "../../../hooks/AuthProvider.tsx";
import { PlayerTurnDto } from "../../../dtos/GameDtos.ts";

interface Props {
  playerTurns: PlayerTurnDto[];
  pointsToWin: number;
}

export default function PlayGameScoreboard({ playerTurns, pointsToWin }: Props) {
  const { username } = useAuth();

  // Find the current user's turns count
  const currentUserTurnsLength = playerTurns.find((pt) => pt.player === username)?.turns.length || 0;

  // Sort and modify the playerTurns array to meet the requirements
  playerTurns = playerTurns
    .map((pt) => ({
      ...pt,
      turns: pt.turns.slice(0, currentUserTurnsLength), // Limit the turns to the length of the current user's turns
    }))
    .sort((a, b) => {
      if (a.player === username) return -1; // Current user comes first
      if (b.player === username) return 1;
      return 0;
    });

  return <Scoreboard playerTurns={playerTurns} pointsToWin={pointsToWin} firstAtTop={false} />;
}
