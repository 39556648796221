import { useState } from "react";
import { Link } from "react-router-dom";
import { sendBulkDeleteNotification, useNotificationList } from "../api/NotificationsApi.ts";
import { Loader } from "lucide-react";
import Button from "react-bootstrap/Button";
import { useAxios } from "../hooks/useAxios.ts";
import { NotificationBasicDto } from "../dtos/NotificationDtos.ts";

export default function Notifications() {
  const axios = useAxios();
  const { data: notifications, isLoading: notificationLoading, mutate: mutateNotifications } = useNotificationList();
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>([]);

  const viewedNotifications = notifications ? notifications.filter((notification) => notification.viewed) : [];
  const notViewedNotifications = notifications ? notifications.filter((notification) => !notification.viewed) : [];

  const handleCheckboxChange = (id: number) => {
    setSelectedNotifications((prev) => (prev.includes(id) ? prev.filter((notifId) => notifId !== id) : [...prev, id]));
  };

  const handleSelectAll = (notificationGroup: NotificationBasicDto[]) => {
    const allIds = notificationGroup.map((notification) => notification.id);
    setSelectedNotifications((prev) => {
      const allSelected = notificationGroup.every((n) => prev.includes(n.id));
      if (allSelected) {
        // If all are selected, deselect them
        return prev.filter((id) => !allIds.includes(id));
      } else {
        // If not all are selected, select all
        const newSelection = new Set([...prev, ...allIds]);
        return Array.from(newSelection);
      }
    });
  };

  const handleDeleteSelected = () => {
    const beforeDeletion = notifications!;
    const notificationsAfterDeletion = [...notifications!.filter((notification) => !selectedNotifications.includes(notification.id))];

    mutateNotifications(notificationsAfterDeletion, false);

    sendBulkDeleteNotification(axios, selectedNotifications).catch(() => mutateNotifications(beforeDeletion, false));
  };

  const getNotificationRow = (notification: NotificationBasicDto) => {
    return (
      <li key={notification.id}>
        <input type="checkbox" checked={selectedNotifications.includes(notification.id)} onChange={() => handleCheckboxChange(notification.id)} />
        <Link to={`/notifications/${notification.id}`}>
          {notification.newPlayer} just finished game {notification.game}
        </Link>
      </li>
    );
  };

  if (notificationLoading) return <Loader />;

  return (
    <>
      <h1>Notifications</h1>
      <hr />
      <h3>New Notifications</h3>
      {notViewedNotifications.length > 0 && (
        <label>
          <input type="checkbox" checked={notViewedNotifications.every((n) => selectedNotifications.includes(n.id))} onChange={() => handleSelectAll(notViewedNotifications)} />
          Select All New
        </label>
      )}
      <ul style={{ listStyleType: "none" }}>{notViewedNotifications.map((notification) => getNotificationRow(notification))}</ul>
      <hr />
      <h3>Read Notifications</h3>
      {viewedNotifications.length > 0 && (
        <label>
          <input type="checkbox" checked={viewedNotifications.every((n) => selectedNotifications.includes(n.id))} onChange={() => handleSelectAll(viewedNotifications)} />
          Select All Read
        </label>
      )}
      <ul style={{ listStyleType: "none" }}>{viewedNotifications.map((notification) => getNotificationRow(notification))}</ul>
      <Button variant="danger" onClick={handleDeleteSelected}>
        Delete Selected
      </Button>
    </>
  );
}
