import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { verifyEmailRequest } from "../api/AuthenticationApi.ts";
import { useAxios } from "../hooks/useAxios.ts";
import useRequiredParams from "../hooks/useRequiredParams.ts";

export default function EmailVerification() {
  const { token } = useRequiredParams();
  const [verified, setVerified] = useState(false);
  const axios = useAxios();
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token || verified) return;

      try {
        const response = await verifyEmailRequest(axios, token);
        if (response.data.success) {
          setVerified(true);
        } else {
          setVerified(true);
          setError("Email verification failed.");
        }
      } catch (err) {
        setError("An error occurred while verifying your email.");
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div className="email-verification">
      {verified ? (
        <div>
          <h1>Email Verified</h1>
          <p>Your email has been successfully verified.</p>
          <Button onClick={() => navigate(`/sign-in`)}>Go to Sign In</Button>
        </div>
      ) : (
        <div>
          <h1>Email Verification</h1>
          {error ? <p>{error}</p> : <p>Verifying your email...</p>}
        </div>
      )}
    </div>
  );
}
