import { Link } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider.tsx";
import { Navbar as ReactNavbar, Nav, Container } from "react-bootstrap";

export default function Navbar() {
  const { authToken, username } = useAuth();

  return (
    <ReactNavbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <ReactNavbar.Brand as={Link} to="/">
          Async Darts
        </ReactNavbar.Brand>
        <ReactNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <ReactNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {authToken && (
              <Nav.Link as={Link} eventKey={2} to="/site-wide-stats">
                Site Wide Stats
              </Nav.Link>
            )}
            {authToken && (
              <Nav.Link as={Link} eventKey={2} to="/notifications">
                Notifications
              </Nav.Link>
            )}
            {authToken && (
              <Nav.Link as={Link} eventKey={2} to="/user_search">
                User Search
              </Nav.Link>
            )}
            {authToken && (
              <Nav.Link as={Link} eventKey={2} to="/games">
                Games
              </Nav.Link>
            )}
            <Nav.Link as={Link} eventKey={2} to="/privacy">
              Privacy
            </Nav.Link>
          </Nav>

          <Nav>
            {authToken && (
              <Nav.Link as={Link} eventKey={2} to={`/user/${username}`}>
                {username}'s Profile
              </Nav.Link>
            )}
            {!authToken && (
              <Nav.Link as={Link} eventKey={2} to="/sign-in">
                Sign In
              </Nav.Link>
            )}
            {!authToken && (
              <Nav.Link as={Link} eventKey={2} to="/sign-up">
                Sign Up
              </Nav.Link>
            )}
            {authToken && (
              <Nav.Link as={Link} eventKey={2} to="/sign-out">
                Sign Out
              </Nav.Link>
            )}
          </Nav>
        </ReactNavbar.Collapse>
      </Container>
    </ReactNavbar>
  );
}
