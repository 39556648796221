export default function PrivacyPolicy() {
  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        maxWidth: "600px",
        margin: "auto",
        marginTop: "15px",
      }}
    >
      <h2>Privacy Notice</h2>
      <p>
        We value your privacy. We only store your <strong>username</strong> and <strong>email address</strong>. We do not share this information with any third parties.
      </p>
      <p>
        We do not track any other data about you at this time. You will only receive emails from us when requested, such as for password resets, or if we need to notify you of important
        changes.
      </p>
      <p>
        We do not track any other data about you at this time. You will only receive emails from us when requested, such as for password resets, or if we need to notify you of important
        changes.
      </p>
      <p>If we start tracking any additional data in the future, we will inform you beforehand.</p>
    </div>
  );
}
