import { TurnUtil } from "../PlayGamePage/TurnUtil.ts";
import { PlayerTurnDto } from "../../../dtos/GameDtos.ts";

interface Props {
  playerTurns: PlayerTurnDto[];
  pointsToWin: number;
}

interface LeaderboardRow {
  username: string;
  turns: number;
  finalTurnThrows: number;
  averagePoints: number;
}

export default function Leaderboard({ playerTurns, pointsToWin }: Props) {
  function mapToLeaderboard(playerTurns: PlayerTurnDto[]): LeaderboardRow[] {
    return playerTurns
      .filter(({ isDone }) => isDone)
      .map(({ player, turns }) => {
        const totalTurns = turns.length;
        const finalTurnThrows = TurnUtil.getNumThrows(turns[totalTurns - 1]);
        const averagePoints = pointsToWin / totalTurns;

        return {
          username: player,
          turns: totalTurns,
          finalTurnThrows: finalTurnThrows,
          averagePoints: averagePoints,
        };
      });
  }

  function sortLeaderboard(leaderboard: LeaderboardRow[]): LeaderboardRow[] {
    return leaderboard.sort((a, b) => {
      if (a.turns !== b.turns) {
        return a.turns - b.turns;
      }
      return a.finalTurnThrows - b.finalTurnThrows;
    });
  }

  const leaderboardRows = mapToLeaderboard(playerTurns);
  const sortedLeaderboard = sortLeaderboard(leaderboardRows);

  return (
    <>
      <h2>Leaderboard</h2>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr className="text-center">
              <th>Position</th>
              <th>Player</th>
              <th>Turn Finished On</th>
              <th>Throw Finished On</th>
              <th>Average Points Per Turn</th>
            </tr>
          </thead>
          <tbody>
            {sortedLeaderboard.map((row, index) => {
              return (
                <tr className="text-center">
                  <td>{index + 1}</td>
                  <td>{row.username}</td>
                  <td>{row.turns}</td>
                  <td>{row.finalTurnThrows}</td>
                  <td>{+row.averagePoints.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
