import { Link, useNavigate } from "react-router-dom";
import { sendDeleteNotification, useNotificationDetail } from "../../api/NotificationsApi.ts";
import FinishedGameScoreboard from "../GameView/Scoreboard/FinishedGameScoreboard.tsx";
import { Loader } from "lucide-react";
import { useAxios } from "../../hooks/useAxios.ts";
import { TurnUtil } from "../GameView/PlayGamePage/TurnUtil.ts";
import { Button } from "react-bootstrap";
import styles from "./NotificationView.module.css";
import useRequiredParams from "../../hooks/useRequiredParams.ts";

export default function NotificationView() {
  const { notificationId } = useRequiredParams();
  const navigate = useNavigate();
  const axios = useAxios();
  const { data: notificationData, isLoading } = useNotificationDetail(notificationId);

  const getWinLossString = () => {
    if (notificationData!.oldPlayerTurns.turns.length == notificationData!.newPlayerTurns.turns.length) {
      const numOldPlayerFinalThrows =
        notificationData!.oldPlayerTurns.turns.length > 0 ? TurnUtil.getNumThrows(notificationData!.oldPlayerTurns.turns[notificationData!.oldPlayerTurns.turns.length - 1]) : 0;
      const numNewPlayerFinalThrows =
        notificationData!.newPlayerTurns.turns.length > 0 ? TurnUtil.getNumThrows(notificationData!.newPlayerTurns.turns[notificationData!.newPlayerTurns.turns.length - 1]) : 0;
      if (numOldPlayerFinalThrows == numNewPlayerFinalThrows) return "tied to";
      if (numOldPlayerFinalThrows > numNewPlayerFinalThrows) return "lost to";
      return "beat";
    } else if (notificationData!.oldPlayerTurns.turns.length > notificationData!.newPlayerTurns.turns.length) {
      return "lost to";
    }
    return "beat";
  };

  const handleDeleteButtonPressed = () => {
    sendDeleteNotification(axios, notificationId).then(() => navigate("/notifications"));
  };

  if (isLoading) return <Loader />;

  const toNextNotification = (shouldDelete: boolean) => {
    if (shouldDelete) {
      sendDeleteNotification(axios, notificationId);
    }
    navigate("/notifications/" + notificationData!.nextNotification);
  };

  return (
    <>
      <h1>
        You {getWinLossString()} {notificationData!.newPlayer}
      </h1>
      <Link to={`/games/${notificationData!.game}`}>Game {notificationData!.game}</Link>
      <hr />
      You finished in {notificationData!.oldPlayerTurns.turns.length > 0 ? notificationData!.oldPlayerTurns.turns.length : 0} turns and{" "}
      {notificationData!.oldPlayerTurns.turns.length > 0 ? TurnUtil.getNumThrows(notificationData!.oldPlayerTurns.turns[notificationData!.oldPlayerTurns.turns.length - 1]) : 0} throws.
      <br />
      {notificationData!.newPlayer} finished in {notificationData!.newPlayerTurns.turns.length > 0 ? notificationData!.newPlayerTurns.turns.length : 0} turns and{" "}
      {notificationData!.newPlayerTurns.turns.length > 0 ? TurnUtil.getNumThrows(notificationData!.newPlayerTurns.turns[notificationData!.newPlayerTurns.turns.length - 1]) : 0} throws.
      <FinishedGameScoreboard playerTurns={[notificationData!.oldPlayerTurns, notificationData!.newPlayerTurns]} pointsToWin={notificationData!.pointsToWin} />
      <div>
        {notificationData!.nextNotification && (
          <>
            <Button className={styles.buttonMargin} onClick={() => toNextNotification(false)}>
              Next
            </Button>
            <Button className={styles.buttonMargin} variant="warning" onClick={() => toNextNotification(true)}>
              Next And Delete
            </Button>
          </>
        )}
        <Button variant="danger" onClick={handleDeleteButtonPressed}>
          Delete Notification
        </Button>
      </div>
    </>
  );
}
