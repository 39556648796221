import useSWR from "swr";
import { useFetcher } from "../hooks/userFetcher.ts";
import { AxiosInstance } from "axios";
import { FriendListDto } from "../dtos/FriendsDtos.ts";

const friendListUrl = `/friends/`;
const updateFriendRequestUrl = "/friends/update/";

export function useFriendsList() {
  const { data, error } = useSWR<FriendListDto>(friendListUrl, useFetcher());
  return {
    friendList: data,
    isLoading: !data,
    isError: !!error,
  };
}

export async function updateFriendStatusRequest(axios: AxiosInstance, username: string, newStatus: string) {
  return await axios.post<FriendListDto>(updateFriendRequestUrl, { update_type: newStatus, other_user: username });
}
